<template>
  <div>
    <div v-show="detailFlag">
      <div class="el-dialog__wrapper">
      </div>
      <div class="detail">
        <div class="header">
          查看详情
          <i class="el-icon-close"
             @click="handleClose"></i>
        </div>
        <div class="content">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>区域：{{infoDetail.areaName}}</div>
            </el-col>
            <el-col :span="12">
              <div class='detail1'>操作人：{{infoDetail.operatorName}}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>车牌号：{{infoDetail.plateNumber}}</div>
            </el-col>
            <el-col :span="12">
              <div class='detail1'>操作时间：{{infoDetail.updatedTime}}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>商户名称：{{infoDetail.operationName}}</div>
            </el-col>
            <el-col :span="12">
              <div class='detail1'>修改原因：{{infoDetail.updateDebtReasonName}}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>停车场名称：{{infoDetail.parentParkName}}</div>
            </el-col>
            <el-col :span="12"
                    v-if="infoDetail.correctExitTime">
              <div class='detail1'>矫正出场时间：{{infoDetail.correctExitTime | formatDate}}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>入场时间：{{infoDetail.entryTime | formatDate}}</div>
            </el-col>
            <el-col :span="12">
              <div class='detail1'>矫正订单金额：{{(infoDetail.correctMoney / 100).toFixed(2)}}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>出场时间：{{infoDetail.exitTime | formatDate}}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>停车时长：{{infoDetail.parkTime}}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class='detail1'>订单金额：{{(infoDetail.money / 100).toFixed(2)}}</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'picDetail',
  data () {
    return {

    }
  },
  props: {
    detailFlag: {
      default: false
    },
    infoDetail: {
      type: Object
    }
  },
  mounted () {
  },
  computed: {
  },
  filters: {
    formatDate: function (time, fmt) {
      fmt = fmt || 'yyyy-MM-dd HH:mm:ss';
      let date = new Date(parseInt(time));
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
      }
      return fmt;
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    }
  },
  created () {
  }
}
</script>
<style media="screen">
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-dialog__wrapper
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  overflow auto
  margin 0
  z-index 2005
  width 100%
  height 100
  background #000
  opacity 0.3
.detail1
  line-height 34px
.pagerWrapper
  .el-pagination
    text-align right
    padding-top 20px
.fade-enter-active, .fade-leave-active
  transition opacity 0.5s
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
  opacity 0
.detail
  width 61.4%
  position fixed
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 3000
  box-sizing border-box
  padding 20px 0
  .header
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .content
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
    .picWrapper
      .picBox
        float left
        width 33.33333%
        padding 15px
        box-sizing border-box
      .title
        font-family MicrosoftYaHei
        font-size 12px
        color #8091A5
        letter-spacing 0
        line-height 16px
    .infoWrapper
      border-top 1px solid #D3DCE6
      padding-top 20px
      .title
        height 42px
        line-height 42px
        color #8391a5
        font-size 14px
        width 100%
        border-bottom 1px solid #d1dbe5
        margin 0 0 15px
      .info
        font-size 12px
        color #313541
        letter-spacing 0
        line-height 32px
        overflow hidden
        .infoItem
          width 33.3333%
          box-sizing border-box
          float left
          .label
            width 100px
            text-align right
            display inline-block
</style>

<style scoped>
</style>
