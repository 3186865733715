<template>
  <div>
    <!--修改记录查询区域-->
    <div class="searchWrapper">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <!--操作 第一行-->
      <el-form ref="form" :model="formInline" label-width="100px">
        <div class="col_box">
          <div class="col_left">
            <el-form-item label="停车场名称:">
              <my-component
                ref="parkInput"
                :operationId="formInline.operationId"
                @valueChange="completeValue"
                slaveRelations="0,1"
                :parkNameValue="parkNameValue"
              ></my-component>
            </el-form-item>
            <el-form-item label="车牌号:">
              <el-autocomplete
                size="12"
                valueKey="plateNumber"
                class="inline-input"
                v-model="formInline.plateNumber"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete> </el-form-item
            ><el-form-item label="操作人:">
              <el-autocomplete
                class="inline-input"
                v-model="bossManagerId"
                :fetch-suggestions="querySearchAsync1"
                placeholder="请输入内容"
                value-key="bossManagerName"
                :trigger-on-focus="false"
                @select="handleSelect1"
              ></el-autocomplete> </el-form-item
            ><el-form-item label="操作时间: ">
              <el-date-picker
                v-model="date1"
                type="datetime"
                placeholder="选择日期"
                @change="setStartTime"
              ></el-date-picker
              >至
              <el-date-picker
                v-model="date2"
                type="datetime"
                placeholder="选择日期"
                @change="setEndTime"
              ></el-date-picker>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              v-if="$route.meta.authority.tabs.oweOrder.tabs.updateList.button.query"
              @click="
                pageNum = 1;
                searchData();
              "
              >{{ $t('button.search') }}</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <!--列表-->
    <div class="tableWrapper">
      <el-table style="width: 100%" :data="tableData">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column align="center" label="操作" v-if="true" width="80">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="padding: 0"
              v-if="$route.meta.authority.tabs.oweOrder.tabs.updateList.button.detail"
              @click="handleClick(scope.row.updatedebtOrderId)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          v-if="total != 0"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <refund-detail
      :infoDetail="record"
      :detailFlag.sync="dialogVisible"
      @closeDialog="handleClose"
    ></refund-detail>
  </div>
</template>
<script>
import { setIndex, dateFormat } from "../../common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import refundDetail from "@/components/refundDetail/refundDetail";
export default {
  props: ["authority"],
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    let startTime = date1;
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    return {
      value4: "",
      dialogVisible: false,
      parkNameValue: "",
      tableData: [],
      bossManagerId: "",
      bossManagerName: "",
      record: {},
      formInline: {
        parkId: "",
        parkNumber: "",
        carId: "",
        startTime: "",
        endTime: "",
        operator: "",
      },
      date1: startTime,
      date2: endTime,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parentParkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "160",
          formatter: (row) => {
            if (!row.entryTime) {
              return "";
            }
            return this.$moment(parseInt(row.entryTime)).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          width: "160",
          formatter: (row) => {
            if (!row.exitTime) {
              return "";
            }
            return this.$moment(parseInt(row.exitTime)).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          prop: "money",
          label: this.$t("list.order_amount"),
          width: "80",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "correctExitTime",
          label: "矫正出场时间",
          width: "120",
          formatter: (row) => {
            if (!row.correctExitTime) {
              return "";
            }
            return this.$moment(parseInt(row.correctExitTime)).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          prop: "correctMoney",
          label: "矫正订单金额",
          width: "120",
          formatter: (row) => {
            if (row.correctMoney) {
              return Number(row.correctMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "120",
        },
        {
          prop: "updatedTime",
          label: this.$t("list.Operation_time"),
          width: "180",
        },
      ],
    };
  },
  methods: {
    goquery() {
      this.$router.replace({
        path: "/oweOrder",
        name: "oweOrder",
        params: {
          tabsVal: "1",
        },
      });
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    querySearchAsync1(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/bossManager/list", {
          data: {
            page: 0,
            size: 20,
            bossManagerName: queryString,
          },
        })
        .then((res) => {
          if (res.value.list.length > 0) {
            this.formInline.operator = res.value.list[0].bossManagerId;
            this.bossManagerId = res.value.list[0].bossManagerName;
          } else {
            this.formInline.operator = "";
          }
          cb(res.value.list);
        });
    },
    handleSelect1(item) {
      this.formInline.operator = item.bossManagerId;
      // this.bossManagerName = item.bossManagerName;
    },
    handleClick(id) {
      this.dialogVisible = true;
      let opt = {
        url: "/acb/2.0/updateDebtOrder/getbyid/" + id,
        method: "get",
        data: {},
        success: (res) => {
          this.record = res.value;
        },
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    completeValue(item) {
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    completeValue1(item) {
      this.formInline.operator = item.bossManagerId;
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    showLog() {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime || this.date1;
        this.formInline.endTime = endTime || this.date2;
        var time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("开始时间不能大于结束时间");
          return false;
        } else if (time >= 31 && this.formInline.carId == "") {
          this.$alert("仅能查出31天内的记录");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 搜索
    searchData() {
      if (this.formInline.parkId == "") {
        this.$refs.parkInput.setShowVal("");
      }
      if (!this.bossManagerId) {
        this.formInline.operator = "";
      }
      let flag = this.showLog();
      this.loading = false;
      if (flag) {
        this.$axios
          .get("/acb/2.0/updateDebtOrder/list", {
            data: {
              page: this.pageNum,
              size: this.pageSize,
              startTime: this.formInline.startTime,
              endTime: this.formInline.endTime,
              operator: this.formInline.operator,
              parkId: this.formInline.parkId,
              plateNumber: this.formInline.plateNumber,
              carId: this.formInline.carId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.tableData = setIndex(this.pageNum, res.value.list);
              this.total = res.value.total * 1 || 0;
            }
          });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
  created() {
    console.log("订单修改历史权限=>", this.$route.meta.authority);
  },
  components: {
    myComponent,
    refundDetail,
  },
  mounted() {
    this.searchData();
    // console.log('authority', this.authority)
    // this.searchData();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-form {
  padding-top: 25px;
  box-sizing: border-box;
  background: #fff;
}

.nav-botton {
  height: 50px;
  margin-top: 20px;
}

.el-form-item {
  display: inline-block;
}

.options-bottom {
  display: flex;
}

.inquire {
  margin-left: 20px;
}

.goquery {
  color: blue;
  cursor: pointer;
}
</style>
